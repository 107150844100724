<template>
  <!-- 工作组内容-->
  <div class="box">
    <card :title="title">
      <div v-for="(item, index) in list" :key="index" class="centbox">
        <div v-if="title != '基准说明'" class="title flexspb">
          <div>
            ● {{ item.detail.title }}{{ item.detail.name }}
            <span class="version" v-if="title == '基准工具'">{{
              item.detail.version
            }}</span>
          </div>
          <div
            @click="todown(item.detail)"
            v-if="item.detail.fileName"
            class="btndown flexcent"
          >
            下载
          </div>
        </div>
        <div
          class="ql-editor"
          v-if="item.htmltext"
          v-html="item.htmltext"
        ></div>
        <div v-if="!item.htmltext">暂无数据</div>
      </div>
    </card>
  </div>
</template>

<script>
import card from "../../all/card"; //面包屑
export default {
  name: "smallcard", //工作组
  props: {
    // 当前的页码(1级菜单)
    list: {
      typeof: [Array],
      default: 1,
    },
    // 当前的标题
    title: {
      typeof: [String],
    },
  },
  components: {
    card,
  },
  methods: {
    //   用户点击下载
    todown(item) {
      // var info = this.until.cacheSync("get", "pigx-userInfo");
      // if (info) {
      //   var obj = {
      //     fileId: item.id,
      //     userId: info.content.userId,
      //   };
      //   this.$api.power(obj).then((res) => {
      //     if (res.data == "toDownload") {
            window.open(
              this.$base.baseUrl +
                `/admin/sys-file/${item.fileRef}/${item.fileName}`,
              "_parent"
            );
      //     } else {
      //       this.$message.warning("权限不足");
      //     }
      //   });
      // } else {
      //   this.$message.warning("2请登录后进行下载");
      // }
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  .title {
    font-size: 1.25rem;
    font-weight: 550;
    height: 4rem;
    .btndown {
      width: 12rem;
      height: 2.4rem;
      border-radius: 0.2rem;
      background-color: #409eff;
      color: #fff;
    }
    .version {
      font-size: 1rem;
      font-weight: 400;
      margin-left: 0.125rem;
    }
    .btndown:hover {
      background-color: #1f5bff;
    }
    .btndown:active {
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: 1023px) {
  /deep/ .more {
    display: none;
  }
  .title {
    font-size: 1rem;
    font-weight: 550;
    flex-wrap: wrap;

    .btndown {
      width: 6rem;
      margin: 0.625rem 0;
      height: 1.6rem;
      border-radius: 0.2rem;
      background-color: #409eff;
      color: #fff;
    }
    .version {
      font-size: 0.7rem;
      font-weight: 400;
      margin-left: 0.1rem;
    }
  }
}
</style>
