<template>
  <div class="box">
    <card title="用户反馈">
      <div class="flexwap feedBackFlex">
        <div>
          联系方式
        </div>
        <div>
          邮箱：cppb_wg@cesi.cn
        </div>
        <!-- <div>
          电话：（010）64102856
        </div>
        <div>
          传真：（010）64007681 
        </div>
        <div>
          邮编：  100007
        </div>
        <div>
          地址：  北京市东城区安定门东大街1号
        </div> -->
        <!-- http://localhost:8080 -->
        <div>
          反馈表：<a href="/admin/sys-file/cppb/CPUBench问题反馈单.docx">点此下载</a> 
        </div>
        
        <!-- <div class="inputtext" v-for="(item, index) in joinlist" :key="index"> -->
          <!-- <div class="title">{{ item.title }}</div>
          <div class="inputcent" v-if="index <4">
            <el-input
              :clearable="!item.password"
              :show-password="item.password"
              v-model="item.value"
              :placeholder="item.plalder"
              :max="item.max"
            ></el-input>
          </div> -->
          <!-- 介绍 -->
          <!-- <div v-if="index == 4" class="inputcent">
            <editor  @editorBlur='editorBlur' class="edtor" />
          </div> -->
        <!-- </div>
        <div class="flexcent btnboot">
          <div @click="submit" class="putup flexcent">提交</div>
        </div> -->
      </div>
    </card>
  </div>
</template>

<script>
import card from "../../all/card"; //封装的卡片样式
// import editor from "../../../components/all/quillEditor";
export default {
  name: "feedback", //表单样式
  props: {
    // 输入的数组
    list: {
      typeof: [Array],
    },
    id:{
        typeof:[String]
    }
  },
  components: {
    // editor,
    card,
  },
  data() {
    return {
      joinlist: [],
      putobj: {}, //需要提交的数据
    };
  },
  methods: {
    editorBlur(e){
      this.joinlist[4].value=e
    },
    // 点击提交数据
    submit() {
      for (let a = 0; a < this.joinlist.length; a++) {
        var item = this.joinlist[a];
        if (item.value == "") {
          return this.$message.warning("请输入" + item.title);
        }
        this.putobj[item.id] = item.value;
      }
      this.putobj.standardId=this.id
      this.initiate();
    },
    // 提交请求
    initiate() {
      this.$api.feedback(this.putobj).then((res) => {
        if (res.code == 0) {
          this.$message.success("提交成功");
          for (let a = 0; a < this.joinlist.length; a++) {
            this.joinlist[a].value = "";
          }
        }
      });
    },
  },
  mounted() {
    this.joinlist = this.list;
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    .inputtext {
      display: flex;
      align-items: flex-start;
      width: 95%;
      margin: 1rem 0;
      .title {
        font-size: 1rem;
        text-align: right;
        width: 6.125rem;
        margin-right: 0.625rem;
      }
      .inputcent {
        flex: 1;
      }
      /deep/ .edtor {
        width: 100%;
        min-height: 15rem;
        
      }
      /deep/ .el-input {
        border: 0.0625rem solid #ececec;
        border-radius: 0.3125rem;
        .el-input__inner {
          height: 1.875rem;
          font-size: 1rem;
          padding-left: 0.625rem;
          border: none;
        }
        .el-input__suffix {
          right: 1rem;
          top: 0.8rem;
        }
        .el-input__clear {
          font-size: 1rem;
        }
      }
    }

    .btnboot {
      width: 100%;
      .putup {
        width: 9rem;
        height: 2rem;
        border-radius: 0.333333rem;
        color: #ffffff;
        background-color: #409eff;
      }
      .putup:hover {
        background-color: #4063ff;
      }
      .putup:active {
        opacity: 0.7;
      }
    }
  }
  .box:hover {
    box-shadow: none;
  }
  .feedBackFlex {
    flex-direction: column;
  }
}

@media screen and (max-width: 1023px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    .inputtext {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      width: 95%;
      margin: 1rem 0;
      .title {
        font-size: .8rem;
        text-align: right;
        width: 5rem;
        margin-right: 0.325rem;
      }
      .inputcent {
        flex: 1;
      }
      /deep/ .edtor {
        width: 100%;
        min-height: 15rem;
        .ql-container {
          height: 11rem;
          padding: 0.625rem;
          font-size: 0.6rem;
        }
        .ql-toolbar {
          height: 8rem;
          box-sizing: border-box;
          .ql-formats {
            font-size: 2rem;
            button {
              width: 1.375rem;
              height: 1.375rem;
            }
            .ql-picker {
              width: 2rem;
              height: 1.375rem;
            }
            .ql-picker-label {
              width: 1.375rem;
              height: 1.375rem;
            }
          }
        }
      }
      /deep/ .el-input {
        border: 0.0325rem solid #ececec;
        border-radius: 0.3125rem;
        .el-input__inner {
          height: 1.5rem;
          font-size: .8rem;
          padding-left: 0.325rem;
          border: none;
        }
        .el-input__suffix {
          right: 1rem;
          top: 0.8rem;
        }
        .el-input__clear {
          font-size: .8rem;
        }
      }
    }

    .btnboot {
      width: 100%;
      .putup {
        width: 5rem;
        margin-left: 5rem;
        height: 2rem;
        border-radius: 0.333333rem;
        color: #ffffff;
        background-color: #409eff;
      }
      .putup:hover {
        background-color: #4063ff;
      }
      .putup:active {
        opacity: 0.7;
      }
    }
  }
  .box:hover {
    box-shadow: none;
  }
}
</style>
