<template>
  <!-- 工作组内容-->
  <div class="box">
    <div class="toptitle flexcent">{{ narlist[secondary].name }}</div>
    <div
      class="cardbox"
      ref="cardheight"
      v-for="(item, index) in list"
      :key="index"
    >
      <smallcard :title="item.title" :list="item.detalist" />
    </div>
    <div class="cardbox feedbox">
      <feedback :id="id" :list="feedlist" />
    </div>
  </div>
</template>

<script>
import feedback from "./feedback"; //联系人
import smallcard from "./smallcard"; //联系人

export default {
  name: "nature", //工作组
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
    narlist:{
      typeof:[Array]
    }
  },
  components: {
    feedback,
    smallcard,
  },

  data() {
    return {
      secondary: 0, //二级菜单的选中样式
      scolist: [0, 0, 0, 0],
      innerht: window.innerWidth,
      feedlist: [
        {
          id: "unitInfo",
          title: "单位名称",
          value: "",
          max: 128,
          plalder: "请输入单位名称",
        },
        {
          id: "linkman",
          title: "联系人",
          value: "",
          max: 128,
          plalder: "请输入联系人",
        },
        {
          id: "linktele",
          title: "联系人电话",
          value: "",
          max: 11,
          plalder: "请输入联系人电话",
        },

        {
          id: "email",
          title: "联系人邮箱",
          value: "",
          max: 30,
          plalder: "请输入联系人邮箱",
        },
        {
          id: "content",
          title: "反馈内容",
          value: "",
          plalder: "请输入反馈内容",
        },
      ],
      id: "",
      list: [
        {
          title: "基准说明",
          detalist: [],
        },
        {
          title: "基准标准",
          detalist: [],
        },
        {
          title: "基准工具",
          detalist: [],
        },
      ],
      istrue: false,
    };
  },
  watch: {
    istrue: function (newValue) {
      if (newValue == true) {
        var list = this.$refs.cardheight;
        var time = setTimeout(() => {
          this.scolist[1] = list[0].offsetTop + list[0].offsetHeight - 60;
          this.scolist[2] = list[1].offsetTop + list[1].offsetHeight - 60;
          this.scolist[3] = list[2].offsetTop + list[2].offsetHeight - 60;
          this.$emit("scolist", this.scolist);
          clearTimeout(time);
        }, 100);
      }
    },
  },
  // 进入页面同步数据
  created() {
    this.secondary = this.chinum; //同步一级菜单
    this.getbilllist();
    console.log('this', this.narlist)
  },
  methods: {
    // 获取第一个数组（拿到当前的id
    getbilllist() {
      this.$api
        .benchmarkList({
          current: 1, //当前页
          size: 20, //页码数量
          title: "", //标题
          status: 1, //状态
          cateroyCode: (this.secondary/1 + 1) * 4, //活动分类
        })
        .then((res) => {
          var data = res.data.records;
          // 拿到当前的id
          this.id = data[0].id;
          // // 获取详情
          this.getfirst(this.id);
          // // 获取第二个数组
          this.getbenmuch(data[0].code);
        });
    },
    // 获取详情以及富文本
    getfirst(id) {
      this.list[0].detalist = [];
      var obj = {};
      this.$api.benchmarkDetail(id).then((res) => {
        obj.detail = res.data;
        this.gethtml(res.data.introduce).then((result) => {
          obj.htmltext = result.content;
          this.list[0].detalist.push(obj);
        });
      });
    },
    //  获取富文本信息
    async gethtml(id) {
      return new Promise((resolve) => {
        this.$api.getWorkHtml(id).then((res) => {
          resolve(res.data);
        });
      });
    },
    // 性能基准
    getbenmuch(code) {
      this.list[1].detalist = [];
      // 相关标准列表
      this.$api.getstandlist({ specCode: code }).then((res) => {
        if (res.data.length > 0) {
          this.$nextTick(() => {
            this.getools(code);
          });
          var list = res.data;
          list.forEach((item) => {
            var obj = {};
            obj.detail = item;
            this.gethtml(item.htmlRef).then((result) => {
              obj.htmltext = result.content;
              this.list[1].detalist.push(obj);
            });
          });
        }
      });
    },
    getools(code) {
      this.list[2].detalist = [];
      this.$api
        .getcppbtoolList({
          status: 1,
          specCode: code,
          typeCode: 0,
        })
        .then((res) => {
          if (res.data.length > 0) {
            var list = res.data;
            list.forEach((item) => {
              var obj = {};
              obj.detail = item;
              this.gethtml(item.htmlRef).then((result) => {
                obj.htmltext = result.content;
                this.list[2].detalist.push(obj);
                this.$nextTick(() => {
                  this.istrue = true;
                });
              });
            });
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  /deep/ .box:hover {
    box-shadow: none;
    background: none;
  }
  .toptitle {
    height: 4rem;
    font-size: 1.75rem;
    font-weight: 550;
  }
  .cardbox {
    margin-bottom: 1.25rem;
  }
  .feedbox {
    /deep/ .box {
      .box {
        background-color: #ffffff;
        border: 0.0625rem solid #eeeeee;
        margin-bottom: 1.25rem;
      }
      .cardhead {
        height: 3.75rem;
        font-size: 1.25rem;
        .title {
          font-size: 1.5rem;
        }
      }
    }
  }
  /deep/ .more {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  /deep/ .more {
    display: none;
  }
  .toptitle {
    height: 3rem;
    font-size: 1.25rem;
    font-weight: 550;
  }
}
</style>
