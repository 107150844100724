<template>
  <div class="narbox">
    <div v-for="(item, index) in joinlist" :key="index" class="nar">
      <div @click="totab(index)" class="firstab">{{ item.title }}</div>
      <div :class="{ transd: index == nowtab }">
        <div
          @click="thitab(ids)"
          class="secondtab"
          v-for="(em, ids) in item.list"
          :key="ids"
        >
          <div>{{ em }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "criterionar", //表单样式
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
    },
  },
  data() {
    return {
      joinlist: [
        {
          title: "通用计算基准", //一级标题
          list: ["基准说明", "基准标准", "基准工具", "用户反馈"],
        },
        {
          title: "通用服务器基准", //一级标题
          list: ["基准说明", "基准标准", "基准工具", "用户反馈"],
        },
      ],
      nowtab: 0, //当前点击的事件
    };
  },
  created() {
    this.nowtab = this.chinum / 1;
  },
  methods: {
    totab(index) {
      this.nowtab = index;
      this.$emit("changenar", { pagenum: 2, chinum: index });
    },
    thitab(index) {
      this.$emit("thitab", index);
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  .firstab {
    height: 3.125rem;
    letter-spacing: 0.125rem;
    line-height: 3.25rem;
    padding-left: 2.25rem;
    border-left: 0.3125rem solid #3b5cf0;
    background-color: #f2f2f2;
    font-size: 1rem;
    font-weight: 550;
    border-bottom: 0.0625rem solid #ffffff;
  }
  .secondtab {
    height: 0;
    transition: height 0.5s;
    opacity: 0;
    padding-left: 3.5rem;
  }
  .firstab:hover {
    cursor: pointer;
    color: #3b5cf0;
  }
  .firstab:active {
    color: #1222b3;
  }
  .secondtab:hover {
    color: #3b5cf0;
    cursor: pointer;
  }
  .secondtab:active {
    color: #1222b3;
  }
  .transd {
    .secondtab {
      opacity: 1;
      height: 2.5rem;
      transition: height 0.5s;
      line-height: 2.5rem;
    }
  }
}

@media screen and (max-width: 1023px) {
  .narbox {
    width: 100%;
    margin-top: -1.25rem;
    .firstab {
      display: none;
    }
    .secondtab {
      height: 0;
      transition: height 0.5s;
      opacity: 0;
      padding-left: 3.5rem;
    }
    .transd {
      display: flex;
      align-content: center;
    .secondtab {
      flex: 1;
      opacity: 1;
      height: 2.5rem;
      transition: height 0.5s;
      line-height: 2.5rem;
      padding: 0;
      text-align: center;
    }
  }
  }
}
</style>
